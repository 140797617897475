const BUTTON_SELECTOR = '.js-tab-button'
const PANEL_SELECTOR = '.js-tab-panel'

/**
 * Tab class
 */
export default class Tab {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor(el) {
    this.el = el
    this.buttonEl = el.querySelectorAll(BUTTON_SELECTOR)
    this.panelEl = el.querySelectorAll(PANEL_SELECTOR)

    this.eventHandler()
  }

  eventHandler() {
    ;[...this.buttonEl].map(el => {
      el.addEventListener('click', this.onButtonClick.bind(this))
    })
  }

  onButtonClick(ev) {
    ev.preventDefault()
    this.switch(ev.currentTarget)
  }

  switch(currentEl) {
    const id = currentEl.getAttribute('href')
    const panelEl = document.querySelector(id)

    ;[...this.buttonEl].map(el => {
      el.setAttribute('aria-selected', 'false')
    })
    ;[...this.panelEl].map(el => {
      el.setAttribute('aria-hidden', 'true')
    })

    if (currentEl) {
      currentEl.setAttribute('aria-selected', 'true')
    }

    if (panelEl) {
      panelEl.setAttribute('aria-hidden', 'false')
    }
  }
}
