/**
 * 画面サイズがSPかどうか
 */
export const isSP = () => {
  return window.innerWidth <= 768
}

/**
 * 引数分の空配列を返す関数
 */
export const range = num => Array.from({ length: num })

/**
 * 遅延関数
 * @param {number} delay
 */
const wait = (delay = 0) => {
  return new Promise(resolve => setTimeout(resolve, delay))
}

/**
 * ランダムな数(int)
 * @param  {[type]} min 最小値(float)
 * @param  {[type]} max 最大値(float)
 * @return {[type]}     [description]
 */
export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * offset.topを返す関数
 */
export const offsetTop = el => {
  const rect = el.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return rect.top + scrollTop
}

/**
 * headerのの高さを返す関数
 */
export const headerHeight = el => {
  const headerEl = document.querySelector('.l-header')
  return headerEl ? headerEl.clientHeight : 0
}
