import { offsetTop } from '~/common/utils'
import { scrollTo } from 'scroll-js'

const SELECTOR = '.js-scroll'

/**
 * ScrollTo class
 */
export default class ScrollTo {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.el = document.querySelectorAll(SELECTOR)

    //
    ;[...this.el].map(_el => {
      _el.addEventListener('click', this.onClick.bind(this))
    })
  }

  onClick(ev) {
    ev.preventDefault()
    const currentTarget = ev.currentTarget
    const id = currentTarget.getAttribute('href')
    this.to(id)
  }

  to(id) {
    const targetEl = document.querySelector(id)
    const headerEl = document.querySelector('.l-header')
    const headerHeight = headerEl ? headerEl.clientHeight : 0

    if (!targetEl) return

    scrollTo(document.body, {
      top: offsetTop(targetEl) - headerHeight,
      easing: 'ease-in-out',
      duration: 500
    })
  }
}
