const TOGGLE_SELECTOR = '.js-menu-toggle'
const OPEN_CLASS = '-open'

/**
 * Menu class
 */
export default class Menu {
  /**
   * [constructor description]
   * @return {[type]} [description]
   */
  constructor() {
    this.toggleEl = document.querySelector(TOGGLE_SELECTOR)

    this.eventHandler()
  }

  eventHandler() {
    if (this.toggleEl) {
      this.toggleEl.addEventListener('click', this.onOpenClick.bind(this))
    }
    document.addEventListener('scroll', this.onScroll.bind(this))
  }

  onOpenClick() {
    if (this.toggleEl.classList.contains(OPEN_CLASS)) {
      this.close()
    } else {
      this.open()
    }
  }

  onScroll() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    if (scrollTop > window.innerHeight / 1.2) {
      this.close()
    }
  }

  open() {
    this.toggleEl.classList.add(OPEN_CLASS)
    this.toggleEl.setAttribute('aria-expanded', true)
  }

  close() {
    this.toggleEl.classList.remove(OPEN_CLASS)
    this.toggleEl.setAttribute('aria-expanded', false)
  }
}
