import 'core-js/stable'
import 'regenerator-runtime/runtime'

import ScrollTo from '~/modules/ScrollTo'
import Tab from '~/modules/Tab'
import Menu from '~/modules/Menu'

document.addEventListener('DOMContentLoaded', () => {
  new Menu()
  new ScrollTo()

  // タブ
  ;[...document.querySelectorAll('.js-tab')].map(el => {
    new Tab(el)
  })

  scrollInit()
})

const scrollInit = () => {
  const el = document.querySelectorAll('.js-scroll')
  const activeClass = '-active'

  //
  ;[...el].map(_el => {
    _el.addEventListener('click', onClick)
  })

  if (location.hash) {
    // await wait(1000)
    // to(location.hash)
  }

  function offsetTop(el) {
    const rect = el.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return rect.top + scrollTop
  }

  function onClick(ev) {
    ev.preventDefault()
    const currentTarget = ev.currentTarget
    const id = currentTarget.getAttribute('href')
    to(id)
  }

  function to(id) {
    const targetEl = document.querySelector(id)

    if (!targetEl) return

    document.dispatchEvent(customEventNavClose)
    document.body.classList.remove('-fixed')

    scrollTo(document.body, {
      top: offsetTop(targetEl) - (isSP() ? 76 : 116),
      easing: 'ease-in-out',
      duration: 1000
    }).then(() => {
      document.body.classList.add(activeClass)
    })
  }
}
